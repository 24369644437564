.ingredient-edit-lang .flag-icon {
    margin-right: 1.2rem;
}

.ingredient-edit-lang .btn-secondary {
    text-align: left;
    cursor: pointer;
}

.ingredient-edit-lang .btn-secondary path {
    fill: #749B3A;
}

.ingredient-edit-lang-item button {
    border: 0;
    background: #fff;
    padding: 0;
    cursor: pointer;
    margin-top: .6rem;
}
