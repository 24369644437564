.ingredient-search {
    padding: 4rem;
    float: left;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.ingredient-search-form {
    position: relative;
}

.ingredient-search-form .form-control-group {
    float: left;
    width: calc(100% - 11rem);
}
.ingredient-search-form input {
    background: #F1F1EF;
    border-color: #F1F1EF;
    padding-left: 4rem;
}

.ingredient-search-form .btn {
    float: left;
    margin-left: 1rem;
    width: 10rem;
}

.ingredient-search-form .icon-search {
    position: absolute;
    left: .8rem;
    top: .8rem;
}

.ingredient-search table {
    width: 100%;
    table-layout: fixed;
}

.ingredient-search table th {
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 1rem 2rem;
}

.ingredient-search table td {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1rem 2rem;
    cursor: pointer;
}

.ingredient-search tr:hover td {
    background: #F6F6F6;
}

.ingredient-search a + a:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 16px;
    background: #000;
    margin: 0px 6px;
    vertical-align: middle;
}
