.ingredients {
    height: 100%;
    position: relative;
}

.ingredient-queue + .ingredient-edit,
.ingredient-queue + .ingredient-search {
    width: calc(100% - 40rem);
}


.ingredient-queue + .ingredient-edit,
.ingredient-queue + .ingredient-search + .ingredient-edit {
    left: 40rem;
}

.ingredient-queue + .ingredient-search + .ingredient-edit .ingredient-edit-content,
.ingredient-queue + .ingredient-search + .ingredient-edit .ingredient-edit-header,
.ingredient-queue + .ingredient-search + .ingredient-edit .ingredient-edit-actions-wrap,
.ingredient-queue + .ingredient-edit .ingredient-edit-content,
.ingredient-queue + .ingredient-edit .ingredient-edit-header,
.ingredient-queue + .ingredient-edit .ingredient-edit-actions-wrap {
    margin-left: 0;
}
