.ingredient-edit section {
    border-bottom: 1px solid #E9E7E4;
    padding-bottom: 4.25rem;
    margin-bottom: 4.25rem;
}

.ingredient-edit section:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.ingredient-edit-info-nav {
    margin: 0 0 2rem;
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid #E9E7E4;
}

.ingredient-edit-info-nav li {
    float: left;
    width: 25%;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
    border-bottom: 4px solid #fff;
    padding: 1rem 0 .5rem;
    cursor: pointer;
}

.ingredient-edit-info-nav li.active {
    color: #749B3A;
    border-bottom-color: #749B3A;
}

.ingredient-edit-info .checkbox-list {
    margin: 0;
}

.ingredient-edit-info .with-trash {
    padding-right: 4rem;
}

.ingredient-edit-info .with-trash button {
    position: absolute;
    right: 1rem;
    top: 3.6rem;
    border: 0;
    background: #fff;
    padding: 0;
    cursor: pointer;
}

.ingredient-edit-info .btn-secondary path {
    fill: #749B3A;
}

.ingredient-edit-info-nutrient-header .form-control-group {
    display: inline-block;
    width: 7rem;
    margin: 0 1rem;
}

.ingredient-edit-info-nutrient .daily-value {
    text-align: right;
    padding-top: 3.6rem;
}
