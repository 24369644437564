
.user-support-search-container {
    text-align: center;
    margin-top: 160px;
}

.user-support-search-container  h1 {

    color: #4E4941;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 54px;
    text-align: center;
}
.user-support-search-container input {
    margin-top: 12px;
    box-sizing: border-box;
    height: 60px;
    width: 767px;
    border: 1px solid #BFB9B2;
    border-radius: 30px;
    padding-left: 70px;
}

.user-support-search-input-container {
    position: relative;
    height: 60px;
    width: 767px;
    margin: 0 auto;
}
.user-support-search-input-container .icon {
    position: absolute;
    left: 30px;
    top: 32px;
}

.user-support-search-container input:focus{
    box-shadow: 0 0 0 1px rgb(69, 88, 235);
    outline:none;
}

