.ingredient-queue {
    float: left;
    height: 100%;
    width: 40rem;
    padding: 4rem;
    border-right: 1px solid #D3D3D3;
    overflow-y: auto;
    position: relative;
}

.ingredient-queue h2 {
    margin-bottom: 1.2rem;
}

.ingredient-queue h2 small {
    float: right;
}

.ingredient-queue-user {
    border-top: 1px solid #E9E7E4;
    border-bottom: 1px solid #E9E7E4;
    margin: 0 0 1.5rem;
    padding: 1rem 0;
}

.ingredient-queue-user .user-picture {
    display: inline-block;
    margin-right: 1.2rem;
    vertical-align: middle;
}

.ingredient-queue .icon-question path {
    fill: #BFB9B2;
}

.ingredient-queue-ingredient-header {
    margin-bottom: 2rem;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    position: relative;
    padding-right: 3rem;
}

.ingredient-queue-ingredient-header div + div {
    font-weight: 400;
}

.ingredient-queue-ingredient-header a {
    position: absolute;
    right: 0;
    top: 0;
}

.ingredient-queue-actions {
    border-top: 1px solid #E9E7E4;
    padding-top: 2rem;
}

.ingredient-queue-actions button {
    cursor: pointer;
}

.ingredient-queue-loadingnext {
    padding: 8rem 0;
}

.ingredient-queue-loadingnext h3 {
    margin-bottom: 2rem;
}

.ingredient-queue-move {
    position: absolute;
    left: 4rem;
    right: 4rem;
    background: #fff;
    border-radius: .5rem;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.35);
    padding: 2rem;
    margin-top: -35rem;
}

.ingredient-queue-move textarea {
    height: 20rem;
}

.ingredient-queue-move button {
    cursor: pointer;
}

.ingredient-queue-note {
    margin-top: 2rem;
    border-top: 1px solid #E9E7E4;
    padding-top: 2rem;
}

.ingredient-queue-note p {
    color: #4E4941;
    font-size: 12px;
    line-height: 18px;
    white-space: pre-wrap;
}
