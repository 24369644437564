#root,
.wrap-outer {
    height: 100%;
}

header {
    height: 7rem;
    padding: 1.5rem;
    border-bottom: 1px solid #D3D3D3;
}

header h1 {
    margin: 0;
}

header h1 .icon {
    width: 3.6rem;
    height: 3.6rem;
    vertical-align: text-bottom;
    margin-right: .5rem;
}

header h1 .icon path {
    fill: #ACC32B;
}

header h1 a {
    color: #4E4941;
    text-decoration: none;
}

.wrap-content {
    float: left;
    width: calc(100% - 24rem);
    height: 100%;
    overflow: auto;
}

.wrap-content.no-nav {
    float: left;
    width: 100%;
    height: 100%;
    overflow: auto;
}

nav {
    float: left;
    width: 24rem;
    height: 100%;
    background: #363649;
}

nav header {
    padding: 2rem 1.5rem;
    border-bottom: 1px solid #252530;
}

nav ul {
    margin: 0;
    padding: 0;
}

nav ul ul {
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

nav li:not(.active) ul {
    height: 0 !important;
    margin-top: 0;
}

nav li {
    position: relative;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #252530;
}

nav li ul {
    margin-top: 1rem;
}

nav li li {
    border-bottom: none;
    padding: 0;
    list-style-type: none;
    text-indent: 3.9rem;
    font-size: 1.4rem;
    line-height: 2rem;
}

nav li li.active {
    background: #4A4A5B;
}

nav li li.active a {
    color: #acc32b;
}

nav a,
nav a:hover {
    display: block;
    text-decoration: none;
    color: #fff;
    line-height: 2.7rem;
}

nav a svg {
    margin-right: 1.5rem;
    vertical-align: text-bottom;
}

nav a[data-total]:after {
    content: attr(data-total);
    position: absolute;
    right: .4rem;
    top: .6rem;
    background: rgba(255,255,255,0.2);
    text-indent: 0;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: .2rem .6rem;
    border-radius: .8rem;
    color: rgba(0,0,0,.5);
    font-weight: 700;
}

nav .icon-eraser path {
    fill: #fff;
}

nav .icon-user path,
nav .icon-bookmark path,
nav .icon-arrow-down path,
nav .icon-recipes path {
    stroke: #fff;
}

nav .expandable {
    position: absolute;
    right: 0;
    top: 0;
    height: 5rem;
    width: 4rem;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: rotate(180deg);
    transform-origin: 50% 50%;
}

nav .expandable:focus {
    outline: 0;
}

nav .expandable .icon-arrow-down {
    width: 1.6rem;
    height: 1.6rem;
}

nav .active .expandable {
    transform: rotate(0);
}

main {
    height: calc(100% - 7rem);
}

h2 {
    font-size: 2rem;
    line-height: 3.2rem;
    margin: 0 0 2rem;
}

h5 {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1.2rem;
}

.wrap-auth {
    height: 100%;
    background: #f6f6f6;
}

.wrap-auth header {
    text-align: center;
    background: #363649;
    border-bottom: 0;
    height: 20rem;
    margin: 0 0 4rem;
    padding: 0;
}

.wrap-auth header img {
    margin-top: 10rem;
    opacity: .95;
    height: 6rem;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-bottom: 2rem;
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}
