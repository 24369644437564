.ingredient-edit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
}

.ingredient-edit-scroll {
    padding: 0 4rem;
    height: calc(100% - 6rem);
    overflow: auto;
}

.ingredient-edit h2 {
    margin-bottom: 1.2rem;
}

.ingredient-edit p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1.2rem;
}

.ingredient-edit-header {
    position: sticky;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #E9E7E4;
    padding: 4rem 0 .5rem;
    z-index: 999;
    margin-bottom: 3rem;
}

.ingredient-edit-header.placeholder {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 3rem;
    padding-top: 0;
    visibility: hidden;
}

.ingredient-edit-header h2 {
    width: calc(100% - 25rem);
    margin-bottom: .6rem;
}

.ingredient-edit-header h2 span {
    vertical-align: top;
}

.ingredient-edit-header h2 .icon {
    margin-left: .5rem;
}

.ingredient-edit-header-actions {
    position: absolute;
    right: 3.5rem;
    bottom: .5rem;
    width: 21rem;
    border-radius: .5rem;
    background-color: #F1F1EF;
    box-shadow: inset 0 0 3px 0 rgba(0,0,0,0.1);
    padding: .5rem;
}

.ingredient-edit-header-actions button {
    background: #F1F1EF;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    width: 10rem;
    height: 3rem;
    border-radius: .3rem;
    color: #7A7366;
    font-size: 1.4rem;
    cursor: pointer;
}

.ingredient-edit-header-actions button.active {
    background: #749B3A;
    color: #fff;
}

.ingredient-edit-header > button {
    position: absolute;
    right: 0;
    bottom: 1rem;
    background: transparent;
    border: none;
    font-size: 4rem;
    line-height: 3rem;
    padding: 0;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
}

.ingredient-edit-header,
.ingredient-edit-content {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;
}

.ingredient-edit-actions-wrap {
    max-width: 73rem;
    padding: 1.1rem 4rem 0;
    margin-left: auto;
    margin-right: auto;
}

.ingredient-edit-actions {
    height: 6rem;
    background: #fff;
    border-top: 1px solid #E9E7E4;
    text-align: right;
}

.ingredient-edit-actions .btn-secondary {
    float: left;
}
