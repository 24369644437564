.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.modal-background {
    background-color: rgba(54,54,73,0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.modal-content {
    position: relative;
    border-radius: 1rem;
    background-color: #FFF;
    min-width: 50rem;
    z-index: 10;
    padding: 5rem 4rem 4rem 4rem;
}

.modal-close {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    height: 18px;
    width: 18px;
    font-size: 25px;
    color: #4D4840;
    cursor: pointer;
}
