button {
    text-decoration: none;
    -webkit-appearance: none;
    border-style: none;
    cursor: pointer;
    outline: none;
    text-align: center;
}

body main button:disabled {
    cursor:default;
    background-color: rgba(19, 1, 1, 0.3);
    border-color: rgba(195, 195, 195, 0.3);
}

button.button-primary {
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 4px;
    background-color: #749B3A

}

button.button-danger {
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 4px;
    background-color: #D54429;

}

button.button-secondary {
    font-weight: bold;
    color: #4E4941;
    border-radius: 4px;
    background-color: #E9E7E4;
}

button.button-large {
    height: 40px;
    width: 240px;
    line-height: 24px;
    font-size: 16px;
}
