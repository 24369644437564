.user-support-customer-page header {
    width: 100%;
    padding-left: 14rem;
}

.user-support-customer-info {
    border-right: .1rem solid #D3D3D3;
    float: left;
    padding-left: 14rem;
    padding-top: 5rem;
    width: 40rem;
    height: 100vh;
    color: #4E4941;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.user-support-customer-info div {
    padding-top: .6rem;
    max-width: 100%;
    overflow-wrap: anywhere;
}

.user-support-customer-info div.user-support-bold-label {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.6rem;
    padding-top: 2rem;
}

.user-support-customer-sub-payment-container {
    position: relative;
    float: left;
    padding: 5rem;
    width: calc(100% - 50rem);
    max-width: 84.3rem;
}

.user-support-customer-sub-conatainer {
    padding-bottom: 4rem;
}

.user-support-customer-sub-payment-container h3 {
    margin-bottom: 2rem;
}

.user-support-customer-sub-payment-container table {
    width: 100%;
}

.user-support-customer-sub-payment-container table th {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.6rem;
}

.user-support-customer-sub-payment-container table tbody tr {
    height: 5rem;
}

.user-support-customer-sub-payment-container table tbody tr:hover {
    background-color: #f7f7f7;
}

.user-support-customer-sub-payment-container .payment-refunded,
.user-support-customer-sub-payment-container .payment-success {
    height: 2rem;
    width: 8.6rem;
    border-radius: .4rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: center;
    font-size: 1.2rem;
}

.user-support-customer-sub-payment-container .payment-success {
    background-color: #F7F9EA;
    color: #749B3A;
}

.user-support-customer-sub-payment-container .payment-refunded {
    background-color: #EDF8FA;
    color: #49A7B7;
}

.uuser-support-sub-action-btn:focus,
.user-support-sub-action-btn {
    height: 2.4rem;
    width: 5.4rem;
    border-radius: .4rem;
    background-color: #E9E7E4;
    border-color:#E9E7E4;
    color: #4E4941;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.6rem;
}

.user-support-customer-payment-total {
    font-size: 2rem;
    line-height: 3.2rem;
}

.user-support-sub-status {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: center;
    border-radius: .4rem;
    float: left;
    padding: 0 1rem
}

.user-support-customer-sub-conatainer tr td:first-child {
    max-width: 17rem;
}

.user-support-customer-sub-active .user-support-sub-icon {
    background-color: #749B3A
}

.user-support-customer-sub-active .user-support-sub-icon .icon path {
    fill: #FFF;
}

.user-support-customer-sub-cancel .user-support-sub-icon .icon g circle {
    stroke-width: 1px;
    fill: #F9E4E0;
}
.user-support-customer-sub-cancel .user-support-sub-icon .icon g {
    stroke-width: 1.5px;
    stroke: #D64525;

}
.user-support-customer-sub-cancel .user-support-sub-icon .icon g rect {
    fill: #D64525
}
.user-support-customer-sub-active .user-support-sub-status{
    background-color: #F7F9EA;
    color: #749B3A;
}

.user-support-customer-sub-cancel .user-support-sub-icon,
.user-support-customer-sub-cancel .user-support-sub-status{
    color: #D64525;
    background-color: #F9E4E0;
}

.user-support-customer-sub-pending .user-support-sub-icon,
.user-support-customer-sub-pending .user-support-sub-status{
    color: #49A7B7;
    background-color: #EDF8FA;
}

.user-support-customer-sub-pending .icon path{
    fill: #49A7B7;
}

.user-support-sub-icon {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: #749B3A;
    text-align: center;
    padding-top: .8rem;
    margin-right: 1.5rem;
    float: left;
}

.user-support-sub-title {
    float: left;
}

.user-support-edit-sub-form label{
    display: block;
    margin-bottom: .8rem;
    font-size: 1.4rem;
    line-height: 2rem;
    opacity: .5;
}

.user-support-date-picker {
    height: 4rem;
    width: 100%;
    padding: .8rem;
    font-size: 1.6rem;
    color: #4E4941;
    border: 1px solid #4D4840;
    border-radius: .4rem;
    margin-bottom: 2rem;
}

.user-support-date-picker input {
    outline: none;
    border: none;
}

.user-support-date-picker  .react-date-picker__wrapper {
    border: none;
}

.user-support-close {
    position: absolute;
    right: 55px;
    top: 55px;
    height: 18px;
    width: 18px;
    font-size: 25px;
    color: #4D4840;
    cursor: pointer;
}
.user-support-edit-container .button-primary {
    margin-top: 1.5rem;
    width: calc(50% - .5rem);
}

.user-support-edit-container .button-secondary {
    margin-top: 1.5rem;
    margin-right: 1rem;
    width: calc(50% - .5rem);
}

.customer-cancel-btn-time {
    box-sizing: border-box;
    height: 95px;
    width: calc(50% - 2rem);
    margin: 0 1rem;
    border: 1px solid #E9E7E4;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin-top: 2rem;
    padding: 2.5rem 0;
}

.customer-cancel-btn-time.selected {
    border: 1px solid #749B3A;
    background-color: #F7F9EA;
}

.customer-info-cancel-modal {
    text-align: center;
}

.customer-info-cancel-modal  hr {
    margin-top: 2rem;
    border: none;
    border-bottom: 1px solid #E9E7E4;
}
.customer-info-cancel-modal .button-primary,
.customer-info-cancel-modal .button-secondary {
    margin-right: 1.5rem;
}

.customer-info-cancel-modal .button-primary a {
    text-decoration: none;
    color: #FFF;
}
